var PeopleMedia = PeopleMedia || {};
PeopleMedia.Login = (function ($jq) {
    "use strict";
    var Oid = "PeopleMedia.Login";

    var _arkoseCaptchaEnabled = false;
    var _arkoseResponseValid = false;

    var _invalidClass = "invalid";
    var _hiddenClass = "hidden";

    // cached selectors
    var $loginForm = $jq();
    var $email = $jq();
    var $password = $jq();
    var $submitButton = $jq();
    var $fieldValidationError = $jq();

    var validateEmail = function (clearOnEmpty) {
        try {
            var email = ($email.val() || "").trim();
            if (email || clearOnEmpty) {
                $email.removeClass(_invalidClass);
                $fieldValidationError.addClass(_hiddenClass);
            } else {
                $email.addClass(_invalidClass);
                $jq("#externalloginerror").html("<p>Please enter a valid email address.</p>");
                $fieldValidationError.removeClass(_hiddenClass);
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "validateEmail" });
        }
    };

    var validatePassword = function (clearOnEmpty) {
        try {
            var password = ($password.val() || "").trim();
            if (password || clearOnEmpty) {
                $password.removeClass(_invalidClass);
                $fieldValidationError.addClass(_hiddenClass);
            } else {
                $password.addClass(_invalidClass);
                if (!$email.hasClass(_invalidClass)) {
                    $jq("#externalloginerror").html("<p>Please enter your password.</p>");
                }
                $fieldValidationError.removeClass(_hiddenClass);
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "validatePassword" });
        }
    };

    var validateForm = function () {
        try {
            var email = ($email.val() || "").trim();
            var password = ($password.val() || "").trim();

            validateEmail(false);
            validatePassword(false);

            var valid = email.length > 0 && password.length > 0;
            if (valid) {
                $submitButton.removeClass(_invalidClass);
            } else {
                $submitButton.addClass(_invalidClass);
            }
            return valid;
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "validateForm" });
            // still allow form to be submitted 
            return true;
        }
    };

    var submitForm = function (e) {
        try {
            e.stopPropagation();
            e.preventDefault();

            $jq("input").trigger("blur");

            var isValid = validateForm();

            if (!isValid) {
                if ($email.hasClass(_invalidClass)) {
                    $email.trigger("focus");
                }
                showErrorMessage();
                return;
            }

            if (_arkoseCaptchaEnabled &&
                PeopleMedia.ArkoseCaptcha.scriptsReady) {
                PeopleMedia.ArkoseCaptcha.execute();
                return;
            }

            submitFormAfterCaptcha();

        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "submitForm" });
        }
    };

    var submitFormAfterCaptcha = function () {
        // don't show for iOS since it doesn't display animations properly on a full page postback
        if (!PeopleMedia.detectIOS()) {
            PeopleMedia.Animations.showLoader({ legacy: false });
        }
        $loginForm.trigger("submit");
    };

    var submitOnEnter = function (e) {
        try {
            var key = e.charCode ? e.charCode : e.keyCode;
            if (key === 13) {
                submitForm(e);
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "submitOnEnter" });
        }
        return true;
    };

    var setFocus = function () {
        try {
            if ($email.val().length > 0) {
                $password.trigger("focus");
            } else {
                $email.trigger("focus");
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "setFocus" });
        }
    };

    var showErrorMessage = function () {
        try {
            $fieldValidationError.removeClass(_hiddenClass);
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "showErrorMessage" });
        }
    };

    var onCaptchaValidateComplete = function (err, response) {
        _arkoseResponseValid = (!err && response && (response.completed || response.suppressed));
        // console.log(response);
        if (_arkoseResponseValid) {
            submitFormAfterCaptcha();
        } else {
            showErrorMessage();
        }
    };

    var emailOnPaste = function (event) {
        setTimeout(function () {
            validateEmail(false);
        }, 0);
    };

    var emailOnChange = function (event) {
        validateEmail(false);
    };

    var emailOnInput = function (event) {
        validateEmail(true);
    };

    var passwordOnPaste = function (event) {
        setTimeout(function () {
            validatePassword(false);
        }, 0);
    };

    var passwordOnChange = function (event) {
        validatePassword(false);
    };

    var passwordOnInput = function (event) {
        validatePassword(true);
    };

    var initialize = function (options) {
        try {
            $loginForm = $jq("#frmLogin");
            $email = $jq("#email");
            $password = $jq("#password");
            $submitButton = $jq(".login-button");
            $fieldValidationError = $jq(".field-validation-error");

            if (options) {
                if (options.arkoseCaptchaEnabled === true) {
                    _arkoseCaptchaEnabled = true;
                }
                if (options.hasError) {
                    showErrorMessage();
                }
            }

            if (_arkoseCaptchaEnabled) {
                PeopleMedia.ArkoseCaptcha.initialize({
                    captchaLocation: "Login",
                    $form: $loginForm,
                    selector: ".login-button",
                    onValidateComplete: onCaptchaValidateComplete,
                    includeCaptchaFieldsInForm: true
                });
            }

            if ($email.val().length > 0) {
                validateEmail();
            }

            $email.on("paste.pm", emailOnPaste)
                .on("change.pm", emailOnChange)
                .on("input.pm", emailOnInput);

            $password.on("paste.pm", passwordOnPaste)
                .on("change.pm", passwordOnChange)
                .on("input.pm", passwordOnInput);

            $email.on("keypress", submitOnEnter);
            $password.on("keypress", submitOnEnter);

            $submitButton.on("click", submitForm);

            setFocus();

            // clear session storage on login
            if (typeof (PeopleMedia.Storage.clearStorage) === "function") {
                PeopleMedia.Storage.clearStorage("MatchStack_");
                // clear stored JWT
                PeopleMedia.Storage.clearStorage("publicSession");
            }
            if (typeof (PeopleMedia.Storage.clearSessionStorage)  === "function") {
                PeopleMedia.Storage.clearSessionStorage();
            }
        } catch (ex) {
            PeopleMedia.Logger.LogExceptionWithDetail(ex, { Oid: Oid, method: "initialize" });
        }
    };

    return {
        initialize: initialize
    };
})(jQuery);